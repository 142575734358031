.profilescreen-wrapper form {
  background-color: rgb(255, 255, 255);
  padding: 5vh;
  padding-top: 5vh;
  border-radius: 3px;
}
.profilescreen-wrapper form textarea.form-control {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 2px #e8e8e8;
}

.profilescreen-wrapper form input[type=text],
.profilescreen-wrapper form input[type=email],
.profilescreen-wrapper form input[type=password],
.profilescreen-wrapper form input[type=date],
.profilescreen-wrapper form input[type=number],
.profilescreen-wrapper form select {
  margin-bottom: 1vh;
  height: 45px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 2px #e8e8e8;
}
.profilescreen-wrapper form input:focus,
.profilescreen-wrapper form select:focus {
  border: 1px solid #e2522e;
  box-shadow: 0 0 2px #e2522e;
}
.profilescreen-wrapper form button {
  background-color: var(--background-blue-color);
  letter-spacing: 1px;
  width: 17vh;
  margin-top: 2vh;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25),
    4px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
.profilescreen-wrapper form button:hover {
  background-color: #0e386e;
}
.profilescreen-wrapper form button.rejectleave-btn {
  background-color: #f14014 !important;
  width: 100% !important;
}