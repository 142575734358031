 .charts-right-cards-sub{
  width: 100%;
  height: 220px; 
  border-bottom: 1px solid #e8e8e8 !important; 
  
 }
 .charts-right-cards-sub2{
  width: 100%;
  height: 220px; 
  display: flex;
  justify-content: space-between;
 }
 .charts-right-cards-sub2-one{
  width: 50%;
  height: auto; 
   border-right: 1px solid #e8e8e8 !important;
 
 }
 .charts-right-cards-sub2-two{
  width: 50%;
  height: auto; 
 }

 

.dashboard-cards-admin {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  margin: 20px 0;
}

.dashboard-cards-admin .card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 15px 25px;
  border: none;
  border-radius: 5px; 
  background: #ffffff;
  /* box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff; */
  border: 1px solid #e8e8e8 !important;
}
.card{
  transition: transform 250ms;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 2px #c5c5c5;
}

.dashboard-cards-admin .card i {
  color: var(--background-blue-color);
  margin-bottom: 2vh;
}

.dashboard-cards-admin .card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-cards-admin .card-inner p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 600;
}

.dashboard-cards-admin .card-inner > span {
  font-size: 25px;
}

.text-title-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.text-title-flex-margin{
  margin-bottom: 0px !important;
}
.text-title-flex-margin-span{
  font-size: 16px !important;
  background-color: #85aec7;
  font-weight: bold;
  padding: 0px 15px;
  border-radius: 10px;
  color: #ffffff !important;
}
