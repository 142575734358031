.container-fluid-job-form {
  background-color: #f6f6f6;
  height: 100vh;
  background-image: url("../img/jobapp3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.form-wrapper {
  display: flex;
  justify-content: center;
}

.job-survey-container {
  margin: 0px auto;
  width: 35%;
  height: 600px;
  background-color: #fff;
}
.job-form-logo {
  padding-top: 15px;
  padding-left: 20px;
}
@media only screen and (max-width: 978px) {
  .job-survey-container {
    width: 100%;
    margin: 0;
  }
}
.header h1 {
  text-align: center;
  font-weight: bold;
  font-weight: 5px !important;
}
.-head {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 0px 0px;
  margin-top: 10px;
  color: #124383;
}
.-head h2 {
  color: #124383;
  letter-spacing: normal;
  text-transform: none;
  margin-top: 5px;
}
.alljob-title h3 {
  color: #124383;
  letter-spacing: normal;
  text-transform: capitalize;
  text-align: center;
}
.alljob-title-second {
  display: flex;
  justify-content: space-between;
}
.survey- {
  background: #fff !important;
  /* overflow: auto; */
}
.-main {
  margin-top: -30px;
}
.-header {
  display: flex;
  justify-content: space-between;
}
.survey- th,
tr,
td {
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 8px;
  color: #5c5c5c;
}
.survey- tbody tr td {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: normal;
}
.-wrapp {
  padding-right: 15px;
  padding-left: 15px;
  margin: auto;
  padding: 10px;
  height: 100vh;
  /* overflow-x: hidden; */
}
.survey- thead {
  background: #124383;
}
.survey- th {
  letter-spacing: 1px;
  font-size: 14px;
  color: #f8f8f9;
  box-shadow: none !important;
  text-transform: none;
  font-weight: normal;
  padding: 10px;
}
.survey- {
  margin: 50px auto;
}

.-main tbody {
  height: 200px !important;
  overflow-y: scroll;
  overflow-x: scroll;
}

.survey-navigation {
  float: right;
  margin: 0px 30px;
}

.job-survey-container .header {
  padding: 10px 40px;
  background-color: #124383;
}
.job-survey-container .header h1 {
  text-transform: none;
  letter-spacing: normal;
  vertical-align: baseline;
}
.form-main {
  font-size: small;
  overflow-y: auto;
  max-height: 500px;
  padding: 20px 40px;
}
.form-main input,
select {
  margin-bottom: 5px;
}
.form-main p {
  color: #5c5c5c;
  font-weight: bold;
  margin: 5px 0px;
}
.header h1 {
  font-size: 25px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 500;
  margin: 10px auto;
}
.form-main input[type="button"] {
  background-color: #124383;
  color: #ffff;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  margin-top: 10px;
}
.form-main input[type="text"],
.form-main input[type="email"],
.form-main input[type="number"],
.form-main textarea,
.form-main select {
  width: 100%;
  outline: none;
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.form-main input[type="text"]:focus,
.form-main input[type="email"]:focus,
.form-main input[type="number"]:focus,
.form-main textarea:focus,
.form-main select:focus {
  outline: none;
  border: 1px solid #e2522e;
  box-shadow: 0 0 1px #e2522e;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.form-main input::-webkit-input-placeholder {
  color: #a0a0a0;
  font-size: 14px;
}
.form-main input::-ms-input-placeholder {
  color: #a0a0a0;

  font-size: 14px;
}
.form-main input::placeholder {
  color: #a0a0a0;
}
.form-main select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1.3em + 2px),
    calc(100% - 15px) calc(1.3em + 2px), calc(100% - 2.5em) 0.9em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}
.job-logo img {
  margin: 10px auto;
  background-repeat: no-repeat;
  float: right;
  height: 30px;
}
.language-type input {
  margin: 0px 8px;
}
.apply-button {
  width: 20%;
  margin-top: 6vh;
  background-color: #e2522e;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  box-shadow: none;
  border: none;
}

.job-survey-container .success-msg {
  margin: 30px auto;
  text-align: center;
}
.job-survey-container .success-msg i {
  color: green;
}
.job-survey-container .success-msg p {
  font-weight: 300;
  margin-top: 20px;
  font-size: large;
}

/* Base styling */
.search__container {
  padding-top: 64px;
}

.search__input {
  background-color: none !important;
  padding: 5px 10px;
  color: #4b4b4b;
  font-weight: 500;
  font-size: medium;
  border-radius: 3px;
  outline: none;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 2px #e8e8e8;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.search__input:focus {
  outline: none;
  border: 1px solid #e2522e;
  box-shadow: 0 0 2px #e2522e;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.search__input::placeholder {
  color: rgba(87, 87, 86, 0.8);
  letter-spacing: normal;
}

.alljob-home-btn a {
  padding: 5px 10px;
  text-transform: none;
  color: #124383;
  font-size: medium;
  font-weight: bold;
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid #124383;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.alljob-home-btn i {
  padding-right: 8px;
}

.-container {
  padding: 30px;
  height: 100%;
}

.alljob-download-btn a {
  padding: 5px 10px;
  text-transform: none;
  color: #136b10;
  border-radius: 3px;
  text-decoration: none;
  border: 1px solid #136b10;
  font-weight: 600;
}
.job-details-btn {
  padding: 5px 5px;
  font-size: 14px;
  color: #c94322;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #c94322;
}
.job-details-btn:hover {
  background-color: #c94322;
  color: #fff;
  text-decoration: none;
}
.alljob-download-btn a:hover {
  color: #136b10 !important;
}
.alljob-download-btn i {
  color: #136b10;
  padding-right: 8px;
}
.search-row {
  display: flex;
  background-color: #fff;
  /* height: 50px; */
  padding: 13px 5px;
  /* justify-content: space-between; */
}
.main {
  background-color: #f8f8f8 !important;
}
.-wrapp {
  padding: 0 !important;
}
.alljob-title h3 {
  color: #124383;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: normal;
}

.update-Modal {
  background-color: var(--background-blue-color);
  padding: 1vh;
  margin: 15px;
  border-radius: 2px;
  color: ghostwhite;
  text-decoration: none;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
a.update-Modal:hover {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35), 2px 2px 2px rgba(0, 0, 0, 0.35),
    4px 4px 4px rgba(0, 0, 0, 0.35), -1px -1px 1px rgba(0, 0, 0, 0.05),
    -2px -2px 2px rgba(0, 0, 0, 0.05), -4px -4px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}

/* All Job   */
.alljob-screen {
  overflow-x: hidden;
  padding: 10px 25px !important;
}
.alljob- {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-top: 2px;
}

.alljob- thead tr {
  background-color: #fff;
  color: #000;
}
.alljob- thead th {
  text-transform: capitalize;
}
.alljob- th,
.alljob- td {
  padding: 10px;
  font-size: 0.9rem;
}
.alljob- .-email a {
  background-color: #f0eeee;
  padding: 2px 6px;
  border-radius: 4px;
}
.alljob- tbody tr {
  background-color: #f7f8f8;
  border-bottom: 1px solid #dddddd;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
}
.alljob- tbody tr td:nth-child(5) {
  text-transform: capitalize;
}
.alljob- tbody tr:hover {
  background-color: #ececec;
}
.alljob- tbody tr:last-of-type {
  border-bottom: 5px solid #ffffff;
}
.alljob- .-avgScore {
  color: #124383;
}

/* All-Job-Application-Pagination-style */
.alljob-title-second {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container-get-job-appli {
  background-color: #f6f6f6;
  height: 100vh;
}

.getjob-application-body {
  margin-top: 2vh;
}

.alljobs-body {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  padding: 0 35px;
}

.details {
  display: flex;
  justify-content: flex-start;
}
.get-second {
  display: flex;
  justify-content: center;
  letter-spacing: normal;
  padding-right: 10px !important;
  margin-top: 10px;
  color: #000;
}
.wrapper {
  display: flex;
}
.get-second span {
  font-weight: normal !important;
  padding-left: 10px;
  text-transform: none;
  font-size: 20px;
}

.getjob-application-body span {
  font-weight: bold;
}
.getjob-application-body div {
  color: #5c5c5c;
  letter-spacing: 0.8px;
  padding-top: 10px;
}
.getjob-application-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.getjob-application-details p {
  font-size: medium;
  margin: 5px 0;
}
.getjob-application-details p:nth-child(odd) {
  font-weight: bold;
}
.getjob-application-details p:nth-child(even) {
  color: #5c5c5c;
}
.getjob-application-details p:nth-child(2) {
  text-transform: capitalize;
}
.getjob-application-details a {
  width: fit-content;
  color: #5c5c5c;
  padding: 5px 10px;
  margin-top: 20px;
  font-weight: bold;
  border-radius: 3px;
  border: 1px solid #5c5c5c;
}

.spoken-languages > p {
  font-weight: bold;
  font-size: medium;
  margin-bottom: 0;
}
.spoken-languages .getjob-application-details p:nth-child(odd) {
  font-weight: 500 !important;
}

.getjob-details-btn {
  background-color: #e2522e;
  color: #fff !important;
  padding: 10px 15px;
  border-radius: 1px;
  text-decoration: none;
}

.update-Modal {
  background-color: var(--background-blue-color);
  padding: 1vh;
  margin: 15px;
  border-radius: 2px;
  color: ghostwhite;
  text-decoration: none;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
a.update-Modal:hover {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35), 2px 2px 2px rgba(0, 0, 0, 0.35),
    4px 4px 4px rgba(0, 0, 0, 0.35), -1px -1px 1px rgba(0, 0, 0, 0.05),
    -2px -2px 2px rgba(0, 0, 0, 0.05), -4px -4px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}

#students {
  text-align: center;
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
  margin-top: 50px;
}

#students th {
  border: 1px solid #ddd;
  padding: 8px;
}

#students td {
  border: 1px solid #ddd;
  padding: 0px !important;
  margin: 0px !important ;
}

#students tr:nth-child(even) {
  background-color: #f2f2f2;
}

#students tr:hover {
  background-color: #ddd;
}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #124383;
  color: white;
}

/* All-Job-Application-Pagination-style */
.pagi-tab {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.paginationBttns {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  font-size: medium;
  font-weight: 800;
}
.paginationBttns a {
  color: #353535;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.paginationDisabled a {
  color: #8d8d8d;
  cursor: default;
}

.paginationActive a {
  background-color: #e2522e !important;
  color: #fff !important;
}

#view-appli {
  background: var(--background-blue-color);
  box-shadow: none;
  padding: 1vh;
  border-radius: 4px;
  color: ghostwhite;
  text-decoration: none;
  margin: 3px;
}

/* get application wrapper and profile card */

/* .get-appli-wrapper {
  width: 100%;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 30px 20px;
  padding-top: 70px;
  display: flex;
  background-image: url("../img/jobapp4.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 768px) {
    height: auto;
    min-height: 100vh;
    padding-top: 100px;
  }
} */

.get-appli-profile-card {
  width: 100%;
  min-height: 550px;
  margin: auto;
  box-shadow: 0px 8px 60px -10px rgba(13, 28, 39, 0.6);
  border-radius: 2px;
  max-width: 800px;
  position: relative;
}
.get-appli-profile-card h3 {
  text-transform: capitalize;
  color: #124383;
  letter-spacing: normal;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

.details-back-btn {
  color: #fff;
  padding-left: 600px;
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}
