/* main {
  background: #f8f8f8;
  grid-area: main;
  overflow-y: auto;
} */
/* .leave-body {
  margin-top: 5vh;
  padding: 0px 35px;
  margin-top: 5vh;
} */
/* .dashboard-body {
  padding: 10px 35px;
  margin-top: 4.5vh;
} */
/* .dashboard-admin  {
  padding: 10px 35px; */
  
  /* margin-top: 4.5vh; */
/* } */

.dashboard-staff-list {
  background-color: #f6f6f6;
  height: 100vh;
}

.dashboard-title {
  display: flex;
  align-items: center;
}

.dashboard-title > img {
  max-height: 100px;
  object-fit: contain;
  margin-right: 20px;
}

.dashboard-greeting > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.dashboard-greeting > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  margin: 20px 0;
}

.dashboard-cards .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  border: none;
  border-radius: 5px; 
  background: #ffffff;
  /* box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff; */
  border: 1px solid #e8e8e8 !important;
}
.card{
  transition: transform 250ms;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 2px #c5c5c5;
}

.dashboard-cards .card i {
  color: var(--background-blue-color);
  margin-bottom: 2vh;
}

.dashboard-cards .card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-cards .card-inner p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 600;
}

.dashboard-cards .card-inner > span {
  font-size: 25px;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 50px;
}

.charts-left {
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  /* box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff; */
  border: 1px solid #e8e8e8 !important;
}

.charts-left-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts-left-title > div > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts-left-title > div > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts-left-title > i {
  color: #ffffff;
  font-size: 20px;
  background: #ffc100;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}
/* --- Dashboard Performance  --- */
.dashboard-performance- {
  width: 100%;
}
.dashboard-performance- thead tr {
  background-color: #ffffff;
}
.dashboard-performance- thead th {
  text-transform: capitalize;
}
.dashboard-performance- th,
.dashboard-performance- td {
  padding: 12px;
  font-size: 0.9rem;
}
.dashboard-performance- tbody tr {
  border-bottom: 1px solid #dddddd;
}
.dashboard-performance- tbody tr {
  background-color: #f7f7f7;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
}
.dashboard-performance- tbody tr:hover {
  background-color: #ececec;
}
.dashboard-performance- tbody tr:last-of-type {
  border-bottom: 5px solid #ffffff;
}
.dashboard-performance- .-avgScore {
  color: #124383;
}

.charts-right {
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  /* box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff; */
  border: 1px solid #e8e8e8 !important;
}

.charts-right-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts-right-title > div > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts-right-title > div > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts-right-title > i {
  color: #ffffff;
  font-size: 20px;
  background: #39447a;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}

.charts-right-cards {
  display: grid;
  grid-template-columns: 1fr  ;
  gap: 20px;
  /* margin-top: 30px; */
}
.charts-bar-card {
  width: 100%;
  display: flex; 
  gap: 20px;
  margin-top: 30px;
}

.card1 {
  background: #d1ecf1;
  color: #35a4ba;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card2 {
  background: #d2f9ee;
  color: #38e1b0;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card3 {
  background: #d6d8d9;
  color: #3a3e41;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card4 {
  background: #fddcdf;
  color: #f65a6f;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

@media only screen and (max-width: 855px) {
  .dashboard-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

 
 
 

 

.fa-maximize{
  font-size: x-large;
  font-weight: 800;
  color: #124383;
}
element.style {
    height: 100%;
      overflow-y: none !important;  
    top: auto;
    padding: 0rem;
    margin: 0rem;
    font-size: 12px;
}



   .modal-fullscreen {
      width: 100vw;
      height: 100vh;
      margin: 0;
    }

      .modal-dialog-fullscreen {
      width: 100%;
      height: 100%;
      margin: 0;
    }

      .modal-content-fullscreen {
      height: 100%;
      border: 0;
    }

    .modal-xl {
  max-width: 100%;
  width: 100%;
  margin: 0;
   height: 100vh;
}

/* .modal-content {
  height: 100vh;
  border: 0;
} */