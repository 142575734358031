.performance-screen-wrapper {
  /* margin-top: 5vh;
  padding: 0 5vh; */
}
.kpi-body {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  column-gap: 1.5rem;
  grid-template-areas: "kpi-performance kpi-legend";
}
.kpi-performance-section {
  grid-area: kpi-performance;
}
.performance-intro {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.performance-intro-header {
  display: flex;
  flex-direction: column;
  background-color: #dfedff;
  padding: 8px 10px 0 10px;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05),
    0 0 4px rgba(0, 0, 0, 0.05);
}
.performance-intro-header div.quarter,
.performance-intro-header div.line-manager {
  display: flex;
  padding-bottom: 0;
  margin-bottom: -5px;
}
.performance-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.performance-intro select {
  height: 30px;
}
.performance-intro select,
.kpi-body select[name="recommendations"],
.performance-intro-header div.quarter p span {
  background-color: #fff;
  padding: 3px;
  color: #4b4b4b;
  font-weight: 500;
  font-size: medium;
  border-radius: 5px;
  outline: none;
  margin-right: 8px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 2px #e8e8e8;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.performance-intro select:focus,
.kpi-body select[name="recommendations"]:focus {
  outline: none;
  border: 1px solid #e2522e;
  box-shadow: 0 0 2px #e2522e;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.performance-intro-header div.quarter p,
.performance-intro-header div.line-manager p {
  color: #4b4b4b;
  font-weight: 500;
  font-size: medium;
  margin-right: 8px;
}
.add-field-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}
.add-field-btn,
.KPI-submit-btn {
  background-color: #e2522e;
  color: #ffff;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.15),
    0 0 16px rgba(218, 209, 209, 0.15);
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.add-field-btn:hover,
.KPI-submit-btn:hover {
  background-color: #c4492a;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.KPI-close-btn:hover {
  background-color: #0f3970;
}
.new-performance-field {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}
.key-performance-box {
  background-color: #ffff;
  padding: 20px 30px;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05),
    0 0 4px rgba(0, 0, 0, 0.05);
}
.key-performance-box-2 {
  background-color: #ffff;
  padding: 20px 30px;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05),
    0 0 4px rgba(0, 0, 0, 0.05);
}
.key-perf-title {
  background-color: #ffff;
  display: grid;
  grid-template-columns: 25rem 3.5rem 5rem;
  column-gap: 10px;
  font-weight: 600;
  font-size: medium;
  color: #5c5c5c;
}
.key-perf-title p {
  margin-bottom: 6px;
}
.key-perf-title.per-title {
  border-bottom: 1px solid #e8e8e8;
}
.key-perf-title.ratings {
  padding-top: 10px;
  border-top: 1px solid #e8e8e8;
  margin-bottom: -10px;
}
.key-perf-title p:nth-child(2) {
  text-align: center;
}
.key-perf-title p:nth-child(3) {
  padding-left: 12px;
}
.key-perf-title.ratings p:nth-child(2) {
  color: #e2522e;
}
.key-perf-title.ratings p:nth-child(3) {
  color: #124383;
}

.development-plan {
  background-color: #ffff;
  text-align: center;
  font-size: medium;
  color: #505050;
  padding-top: 5px;
}
.development-plan p {
  margin: 0;
}
.development-plan p:first-of-type {
  background-color: #fff;
  color: #444444;
  padding: 10px 20px;
  width: fit-content;
  margin: auto;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  padding: 2px 10px;
}
.development-plan p:last-of-type {
  font-weight: 600;
}

.key-performance-box form,
.fields-container {
  max-height: 55vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.fields-container {
  max-height: 56vh !important;
}
.performance-field {
  display: grid;
  grid-template-columns: 25rem 3.5rem 3.5rem;
  column-gap: 10px;
  margin: 14px 0;
  animation: 0.4s customPerf;
}
@keyframes customPerf {
  0% {
    margin-top: -10px;
  }
  100% {
    margin-top: 14px;
  }
}
.performance-field p {
  background-color: #fff;
  color: #444444;
  padding: 10px 20px;
  margin: 0;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.performance-field p:nth-child(2) {
  color: #e2522e;
}
.performance-field p:nth-child(3) {
  color: #124383;
}
.performance-field input[type="text"],
.key-performance-box textarea {
  padding: 10px 15px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 2px #e8e8e8;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.performance-field i {
  color: #e2522e;
  padding: 15px 5px;
  font-size: medium;
  cursor: pointer;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.performance-field i:hover {
  color: #c4492a;
}
.performance-field select,
.performance-field input[type="number"] {
  padding-left: 20px;
  margin-bottom: 0 !important;
  border-radius: 5px;
  outline: none;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 2px #e8e8e8;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.performance-field select,
.kpi-body select[name="recommendations"] {
  cursor: pointer;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23a9a9a9' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.45rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}
.key-performance-box textarea {
  width: 25rem;
  margin-bottom: 20px;
}
.performance-field input[type="text"]:focus,
.key-performance-box form textarea:focus,
.performance-field select:focus {
  outline: none;
  border: 1px solid #e2522e;
  box-shadow: 0 0 2px #e2522e;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.key-performance-box .inputField::-webkit-input-placeholder,
.performance-field .inputField::-webkit-input-placeholder {
  color: #d1d1d1;
}
.key-performance-box .inputField::-ms-input-placeholder,
.performance-field .inputField::-ms-input-placeholder {
  color: #d1d1d1;
}
.key-performance-box .inputField::placeholder,
.performance-field .inputField::placeholder {
  color: #d1d1d1;
}
.key-performance-box .hod-comment {
  color: #5c5c5c;
  margin-top: -10px;
  margin-bottom: 0;
  font-weight: bold;
}
p.hod-comment:nth-child(1) {
  margin-top: 20px;
}

.kpi-body select[name="recommendations"] {
  color: #6b6b6b;
  width: 25rem;
  padding: 8px 10px;
  margin-bottom: 24px;
}

/* --- Recommendations --- */
.key-performance-box #recommendations {
  width: 25rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: -20px 0 20px 0;
}
.key-performance-box .recommendation {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #424242;
  font-weight: bold;
  padding: 5px 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 3px;
  margin: 0 8px 8px 0;
  background: #dfedff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.15),
    0 0 16px rgba(218, 209, 209, 0.15);
}
.recommend-close-icon {
  width: 16px;
  font-weight: bold;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  background: #124383;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.recommend-close-icon:hover {
  transform: scale(1.09);
}

/* --- Overview Screen (Summary) --- */
.performance-info {
  background-color: #dfedff;
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  padding: 10px 0;
  padding-left: 15px;
  border-radius: 3px;
  margin-bottom: 3vh;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05),
    0 0 4px rgba(0, 0, 0, 0.05);
}
.performance-info p {
  color: #444444;
  margin: 0;
  padding: 3px 0;
  font-size: medium;
}
.performance-info p:nth-child(odd) {
  font-weight: bold;
}
.performance-info p:last-of-type {
  background-color: #e2522e;
  color: #fff;
  text-align: center;
  border-radius: 10px 0 0 10px;
  font-weight: bold;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05),
    0 0 4px rgba(0, 0, 0, 0.05);
}

.kpi-legend .other-assessments ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.kpi-legend .other-assessments li {
  background: #ffff;
  color: #424242;
  padding: 4px 8px;
  border-radius: 3px;
  margin-right: 4px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.15),
    0 0 16px rgba(218, 209, 209, 0.15);
}
.kpi-legend .other-assessments p {
  color: #444444;
  margin: 0;
  font-weight: bold;
  /* margin-top: vh; */
}
.kpi-legend .other-assessments textarea {
  background-color: #fff;
  width: 100%;
  color: #505050;
  padding: 6px 9px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 2px #e8e8e8;
}

/* --- KPI Legend--- */
.kpi-legend-section {
  grid-area: kpi-legend;
}
.grading-system {
  background-color: #dfedff;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05),
    0 0 4px rgba(0, 0, 0, 0.05);
}
.grading-system-title {
  padding: 0 25px;
  padding-top: 10px;
}
.grading-system-title > p {
  color: #124383;
  font-weight: 600;
  font-size: medium;
}
.grading-system-body {
  display: grid;
  grid-template-columns: 1fr 5fr;
}
.grading-system-body p:nth-child(odd) {
  color: #5c5c5c;
  font-size: medium;
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #ffffff;
}
.grading-system-body p:nth-child(even) {
  color: #5c5c5c;
  font-size: medium;
  font-weight: 500;
}
.grading-system-body p {
  border-top: 1px solid #ffffff;
  padding: 12px;
  margin: 0;
}

.kpi-summary {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px 25px;
  margin-top: 1rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05),
    0 0 4px rgba(0, 0, 0, 0.05);
}
.kpi-summary-title p {
  color: #080808;
  font-weight: 600;
  font-size: medium;
}
.kpi-summary-body {
  display: grid;
  grid-template-columns: 5fr 1fr;
}
.kpi-summary-body p:nth-child(odd) {
  color: #818181;
  font-weight: 500;
  border-right: 1px solid #ffffff;
}
.kpi-summary-body p:nth-child(even) {
  color: #e2522e;
  font-weight: bold;
}

/* --- HOD Review Title */
.kpi-info-title {
  background-color: #dfedff;
  padding: 5px 10px;
  display: grid;
  grid-template-columns: 3fr 4fr;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05),
    0 0 4px rgba(0, 0, 0, 0.05);
}
.kpi-info-title p {
  font-size: medium;
  margin: 0;
}
.kpi-info-title p:nth-child(odd) {
  color: #124383;
  font-weight: 800;
}
.kpi-info-title p:nth-child(even) {
  color: #124383;
  font-weight: 500;
}
.performance-header .delete-btn {
  position: relative;
}
.performance-header .delete-btn input {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* --- Confirmation Modal --- */
.kpi-modal .modal-content {
  background-color: #fff;
  border-radius: 5px;
}
.kpi-modal .modal-title {
  color: #5c5c5c;
  font-size: medium;
}
.kpi-modal .modal-body {
  color: #5c5c5c !important;
  margin: 0 auto;
  text-align: center;
  padding: 15px;
}
.kpi-modal .modal-body p {
  font-weight: bold;
  margin: 0;
}
.kpi-modal .modal-body p:first-child {
  font-size: x-large;
  margin-bottom: 15px;
}
.kpi-modal .modal-body p:nth-child(2),
.kpi-modal .modal-body p:nth-child(3) {
  font-size: medium;
}
.kpi-modal .modal-body .last-line {
  margin-bottom: 30px;
}
.KPI-close-btn {
  background-color: #124383;
  color: #ffff;
  font-weight: bold;
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.15),
    0 0 16px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

/* --- Delete Reason Modal --- */
.delete-reason-modal textarea {
  width: 100%;
  padding: 10px 13px;
  border-radius: 5px;
  outline: none;
  margin-bottom: 25px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 2px #e8e8e8;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.delete-reason-modal textarea:focus {
  outline: none;
  border: 1px solid #e2522e;
  box-shadow: 0 0 2px #e2522e;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.delete-reason-modal p:last-of-type {
  margin-bottom: 8px;
}
.delete-reason-modal textarea::-webkit-input-placeholder {
  color: #d1d1d1;
}
.delete-reason-modal textarea::-ms-input-placeholder {
  color: #d1d1d1;
}
.delete-reason-modal textarea::placeholder {
  color: #d1d1d1;
}
.info-status {
  background-color: #fff;
  padding: 5rem;
  border-radius: 5px;
  margin: 5vh auto;
  text-align: center;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05),
    0 0 4px rgba(0, 0, 0, 0.05);
}
.info-status i {
  color: #6e6e6d;
}
.info-status i.fa-check-circle {
  color: green;
}
.info-status p {
  font-size: medium;
  font-weight: bold;
  color: #124383;
  margin: 0;
}
.info-status p:first-of-type {
  margin-top: 4vh;
}
.info-status p:last-of-type {
  margin-bottom: 5vh;
}
a.KPI-submit-btn {
  text-decoration: none;
}
a.KPI-submit-btn:hover {
  background-color: #b43f22;
  color: #fff;
}

/* --- Performance Tables --- */
.performance-table {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.performance-table thead tr {
  background-color: #ffffff;
}
.performance-table thead th {
  text-transform: capitalize;
}
.performance-table th  {
  padding: 10px;
  font-size: 0.9rem;
} 
.performance-table td {
  /* padding: 6.5px; */
  font-size: 0.9rem;
}
.performance-table .table-email a {
  background-color: #e5e5e5;
  padding: 2px 6px;
  border-radius: 4px;
}
.performance-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
.performance-table tbody tr {
  background-color: #f7f7f7;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
}
.performance-table tbody tr:hover {
  background-color: #ececec;
}
.performance-table tbody tr:last-of-type {
  border-bottom: 5px solid #ffffff;
}
.performance-table .table-avgScore {
  color: #124383;
}

/* --- Styles for KPI Overview --- */
.HrKpiHeader {
  height: 20%;
  box-shadow: none !important;
  background-color: #c4c4c4 !important;
  padding: 2px !important;
  border: none !important;
}
.table {
  margin-top: 6px !important;
}
.table tbody tr {
  border: none !important;
}
#title {
  border: 1px solid #e8e8e8;
  padding: 4px 8px;
  color: #5c5c5c;
  font-size: 12px;
  width: 35%;
  height: 50%;
  margin-top: 5px;
}

.over td {
  padding: 10px;
}
.agent-details-kpi {
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  text-transform: none;

  background-color: #c4c4c4;
}
.agent-details-kpi:hover {
  background-color: #c94322;
}
.hrempdetail-btn {
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 3px;
  margin-top: 15px;
  background-color: #e2522e !important;
}
.title-head {
  display: grid;
  grid-template-columns: 3.5rem 3.5rem 3.5rem 3.5rem;
  grid-column-gap: 30px;
  column-gap: 30px;
}
.hrcomments {
  margin-top: 25px;
}
.empdetailsform p {
  color: #5c5c5c;
  font-weight: bold;
  margin: 2px 0px;
}

.download-btn a {
  margin-top: 22px;
  padding: 10px 15px;
  text-transform: none;
  background-color: #c94322;
  height: 40px !important;
  color: #fff;
}
.download-btn a:hover {
  color: #fff !important;
  height: 40px !important;
}
.download-btn i {
  color: #c94322;
}
.csv-btn {
  float: right;
  display: flex;
}
.csv-btn i {
  color: #fff;
  position: relative;
  top: 7px;
  right: 5px;
}
