/* mobile side bar */

.text-primary{
  color: #005b90!important;
}
.btn-primary {
    border-color: #005b90 !important;
    /* background-color: #005b90 !important; */
    color: #fff;
}
.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  border-radius: 5px;
}
.sidebar{
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1; 
    transition:.1s; top:0;transition-timing-function: cubic-bezier(0.9,0,1,1);}
aside.close{left:0;transition:.5s;transition-timing-function: cubic-bezier(0.9,0,1,1);
}
.sidebar.active{
    left: 0;
}

.sd-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}
.sd-body{
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
     font-family: "Mulish" !important;
}
.sd-body .ul{
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
}
.sd-body .ul .li{
    list-style: none;
    margin-bottom: 8px;
     font-family: "Mulish" !important;
}
.sd-body .ul .li .sd-link{
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #000;
    /* background-color: #e5e8ec; */
    border-radius: 4px;
    cursor: pointer;
     font-family: "Mulish" !important;
       text-decoration: none;
}
.sd-body .ul .li .on-link {
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #fff;
    background-color: #005b90;
    border-radius: 4px;
    cursor: pointer;
       text-decoration: none;
        font-family: "Mulish" !important;
}

.icon-text{
  color:#adadad !important;
   font-family: "Mulish" !important;
}

 .login-body-img-nav{ 
		padding: 2px !important;
   width: 4rem;  
		 
 }
