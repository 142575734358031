/* Loading Bar */
.dpl-loading--container {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
  transition: opacity ease-in-out 125ms 0ms, visibility ease-in-out 125ms 0ms;
  z-index: 888;
}
.dpl-loading--container.\--is-active {
  opacity: 1;
  visibility: visible;
}

.dpl-loading--container.\--is-active .dpl-loading--progress {
  animation: loading-bar 3s ease-in-out 0s forwards;
}

.dpl-loading--container.\--is-active .dpl-loading--progress.--is-infinite {
  animation: loading-bar-alternate 3s ease-in-out 0s forwards;
}

.dpl-loading--progress {
  display: block;
  height: 0.2rem;
  background: linear-gradient(90deg, #3670bc, var(--background-blue-color));
  transform-origin: left;
  will-change: transform;
}

@keyframes loading-bar-alternate {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  10% {
    transform: scaleX(0.2);
    transform-origin: left;
  }
  20% {
    transform: scaleX(0.6);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  51% {
    transform: scalex(1);
    transform-origin: right;
  }
  80% {
    transform: scalex(0.5);
    transform-origin: right;
  }
  90% {
    transform: scalex(0.2);
    transform-origin: right;
  }
  100% {
    transform: scalex(0);
    transform-origin: right;
  }
}

/* Loading bar styles  */
.loadingbar--container {
  position: relative;
}

.loadingbar--container.position-relative .dpl-loading--container {
  position: relative;
}

/* Repeat class to make animations loop */
.repeat,
.repeat::after {
  animation-iteration-count: infinite !important;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#fa-eye-slash{
  position: absolute;
right: 60px;
bottom: 155px;
}


.roll-title{
	display: flex;
	justify-content: space-between;
}

.roll-icon{
	width: 40px;
	height: 40px;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	border: 1px solid #80808055;
}
.roll-icon:hover {
  width: 40px;
  height: 40px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add box shadow */
}

.half-background{
	background-color: #fff;
	width: 100%;
	height: 100%;
  overflow-y: scroll;
	padding: 1rem;
	border: 1px solid #e8e8e8 !important;
}
.dashboard-table{
  height: 300px;
  overflow-y: scroll;
}
.half-background-input{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#mix-input-icon {
	display: flex;
	align-items: center !important;
	border: 1px solid #124383 !important;
	border-radius: 5px 8px 8px 5px !important;

   
}

#mix-input-icon-Button {
	background-color: #124383 !important;
	color: #fff !important;
	height: 2rem !important;
	width: 30px !important;
	border-radius: 0px 5px 5px 0px !important;
}

#mix-input-icon-Button-input{
	  height: 2rem !important;  
	width: 300px !important;
	border: 0px !important;
  outline: none !important;
  padding: 5px;
  border-radius: 10px;
}

/* // .mix-input-icon:hover {
//     Your styles for .mix-input-icon when hovered  
//   border-color: #99000057;  
// } */

.mix-input-icon-Button:hover {
  /* Your styles for .mix-input-icon-Button when hovered */
  background-color: #3670bca5 !important; /* Example: Change the background color on hover */
  color: #fff !important; /* Example: Change the text color on hover */
}

.mix-input-icon-Button-input:hover {
  /* Your styles for .mix-input-icon-Button-input when hovered */
  /* Add your hover styles here */
}

.pay_paramiter{
	display: flex !important;
}

.CSV-button{
	font-size: small !important;
	height: 2rem !important;
		border: 1px solid #3670bc !important;
		color: #3670bc !important;
  margin-right: 10px !important;
}



/* Default styles for desktop */
.half-background-input {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 240px;
  /* // border: 1px solid c.color(primary-color); */
}

.entries-per-page {
  width: 240px;
}

.pay_parameter {
  display: flex;
  align-items: center;
}

.CSV-button,
.mix-input-icon-Button {
  margin-right: 10px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .half-background-input {
    flex-direction: column;
  }

  .item, .entries-per-page {
    width: 100%;
    margin-bottom: 10px;
  }

  .CSV-button, .mix-input-icon-Button {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .pay_parameter {
    flex-direction: column;
  }
  #mix-input-icon-Button-input{
	width: 200px !important;
}
.pay_parameter a{
  margin-bottom: 20px;
}
}


.view-roll-Image{
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: #f6f6f6;
}

.view-roll-header-card{
  width: 100%;
  height: 160px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #D9D9D9 !important;  
  display: flex;
  align-items: self-end;
  gap: 75px;
  padding: 1rem;
  margin: 1rem 0px;
}

.view-roll-header-text{
  color: #1F1F1F;
  font-weight: 300;
}

#reports .nav-tabs li {
    padding: 0.5rem 1rem !important;
    color: #272525 !important;
    cursor: pointer !important;
    border: 1px solid #d8d8d8 !important;
}


/* modal-custom-header */
.modal-handle-header{
	display: flex;
	justify-content: space-between !important;
	align-items: center !important;
	padding: 1rem;
}

.modal-handle-sub{
	display: flex;
	align-items: center;
}
.modal-handle-sub-title{
	width: 50px;
	height: 50px;
	background-color: #EEEEEE;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	margin-right: 10px;
}

.modal-handle-sub-button{
	/* width: 20px !important;
	height: 20px !important; */
 /* background-color: #EEEEEE !important; */
	color:#000 !important ;
}

.span-center-title-sup{
	color: #797979;
  margin-bottom: 0px !important;
  
}
.span-center-title{ 
  font-size: medium;
    margin-bottom: 0px !important;
}
