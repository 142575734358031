#header {
  grid-area: header; 
  padding: 20px 35px 20px 20px;
  border-bottom: 1px solid #e8e8e8 !important;
}

.navbar {
  background: #fff;
  grid-area: navbar;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 30px;
  box-shadow:  0 2px 0 #f0eeec;
}

.nav-icon {
  display: none;
}

.nav-icon > i {
  font-size: 26px;
  color: #a5aaad;
}

.navbar-left > span {
  margin-right: 30px;
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 700;
  text-transform: lowercase;
  display: none;
}
.navbar-left > span:first-child {
  text-transform: capitalize;
}
.navbar-left > span:nth-child(2) {
  text-transform: lowercase;
}
.navbar-right {
  color: #4b4b4b;
  display: flex;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.navbar-right > div {
  margin-left: 5px;
}
.navbar-right img {
  opacity: 0.7;
}

.navbar-right > a > i {
  color: #a5aaad;
  font-size: 10px;
  background: #fff;
  box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #fff;
}

.navbar .dropdown {
  background-color: #ffffff;
	position: absolute;
	top: 60px;
	width: 250px;
	font-size: 0.9rem;
	transform: translateX(-25%);
	border-radius: 5px;
	overflow: hidden;
	z-index: 999;
	text-transform: capitalize;
	animation-name: dropdown;
	animation-duration: .5s;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 2px 2px 2px rgba(0, 0, 0, 0.05),
	4px 4px 4px rgba(0, 0, 0, 0.05), -1px -1px 1px rgba(0, 0, 0, 0.05),
	-2px -2px 2px rgba(0, 0, 0, 0.05), -4px -4px 4px rgba(0, 0, 0, 0.05);
	-webkit-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
}

@keyframes dropdown {
  from {
    top: 80px;
    opacity: 0.5;
  }
  to {
    top: 60px;
    opacity: 1;
  }
}
.drop-info {
	display: flex;
	padding: 10px 10px;
	color: rgb(105, 105, 105);
	padding-left: 20px;
	border-bottom: 1px solid rgb(230, 230, 230);
}
 
.drop-info span p:first-child {
	margin-bottom: -0.3vh;
}
.navbar .dropdown a {
	text-decoration: none;
	padding: 19px 10px;
	padding-left: 20px;
}
.navbar .dropdown a {
	color: rgb(105, 105, 105);
}
.navbar .dropdown a:hover {
	color: ghostwhite;
	background-color: #124383;
	transition: all 0.15s ease-out;
	-webkit-transition: all 0.15s ease-out;
}
.navbar .dropdown .drop-logout {
	border-top: 1px solid rgb(230, 230, 230);
}

@media only screen and (max-width: 978px) {
  .nav-icon {
    display: inline;
  }
}

@media only screen and (max-width: 480px) {
  .navbar-left {
    display: none;
  }
}


 
 


#header {
  grid-area: header;
  background-color: #fff;
  padding: 20px 35px 20px 20px;
  border-bottom: 1px solid #e8e8e8 !important;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

@media only screen and (max-width: 1220px) {
/* //   .header-container {
//   display: flex;
//   justify-content: space-between;
//   overflow-x: scroll;
// } */
}

.header-container .header-left {
  display: flex; 
  align-items: center;
  gap: 40px;
}

.header-container .FaBars {
  color:  grey;
  cursor: pointer;
  font-size: 1.5rem;
}

.header-container .lob {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.header-container .lob select {
  outline: none;
  padding: 0.1rem 0.25rem;
  border: 1px solid rgb(197, 197, 197);  
}

.header-user-details {
  color:  grey;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--background-blue-color);
  padding: 4px ;
  border-radius: 50px ;
}
.header-container .header-user-details i.fa-user {
  background-color: var(--background-blue-color);
  color: #fff;
  padding: 8px 9px;
  font-size: 0.7rem;
  border-radius: 50%;
}

.dropdown-names {
  color: #1a1a1a;
  margin-left: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}
.header-container .header-user-details i.fa-chevron-down {
  color: #edbcbc;
  font-size: 0.9rem;
}
.header-container span {
  padding: 0 7px;
}

.dropdown-icons-tools {
  margin-right: 1rem;
}

/* --- Dropdown --- */
#header .dropdown {
  background-color: #ffffff;
  position: absolute;
  top: 50px;
  right: 0px;
  width: 180px;
  font-size: 0.9rem;
  transform: translateX(-20%);
  border-radius: 5px;
  overflow: hidden;
  z-index: 999;
  text-transform: capitalize;
  animation-name: dropdown;
  animation-duration: 0.1s;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 2px 2px 2px rgba(0, 0, 0, 0.05),
    4px 4px 4px rgba(0, 0, 0, 0.05), -1px -1px 1px rgba(0, 0, 0, 0.05),
    -2px -2px 2px rgba(0, 0, 0, 0.05), -4px -4px 4px rgba(0, 0, 0, 0.05);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

@keyframes dropdown {
  from {
    top: 84px;
    opacity: 0.5;
  }
  to {
    top: 61px;
    opacity: 1;
  }
}
#header .dropdown a {
  color: #1a1a1a;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  padding: 15px 10px;
  padding-left: 20px;
}
#header .dropdown i {
  font-size: medium;
  margin-right: 15px;
}
#header .dropdown a:hover {
  color: #fff;
  background-color: var(--background-blue-color);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
#header .dropdown a:hover i {
  color: #fff;
}
#header .dropdown .drop-logout {
  border-top: 1px solid #e6e6e6;
}

.preview-header {
  display: block;
  box-shadow: 0px 0px 0px 1px var(--background-blue-color);
  border-radius: 50%;
  overflow: hidden;
  background: none;
}

.img-container-header {
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
.header-logo {
  display: block !important;  
  /* // margin-right: 100px !important;
  // display: flex !important;
  // justify-content: flex-end !important;

  // image-rendering: auto; */
}
.header-logo-mobile{
  display: none;
}
.header-logo img{
  width: 200px !important;
  height: 40px !important;

}

.header-logo-mobile img{
  width:60px;
  height:50px;
 
}

.header-logo-text {
  margin-right: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}
.header-logo-text1 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}

@media screen and (max-width: 1000px) {
  /* // .header-logo img {
  //   display: none !important;
  // } */
}

.hand-noficational-place{
  display: flex;
  align-items: center; 
}

.hand-noficational-place-sup{
   margin-right: 6rem; 
}
  @media screen and (max-width: 1000px) {
  .hand-noficational-place-sup{
   margin-right: 1rem; 
}
}

.Messages-button {
	position: relative;
	border-radius: 4px;  
	cursor: pointer;
	user-select: none;
	transition: all .3s;
  	border-radius: 50%; 
	
	&:hover {
		transform: scale(1.03); 
	}
	
	&:active {
		transform: scale(.96);
		
		.badge {
			transform: scale(1.2);
		}
	}
}

.Messages-button .badge {
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: block;
	position: absolute;
	background:  var(--background-blue-color);
	border: 2px solid white;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -10px;
	right: -5px;
	transition: all .3s;
  padding: 0.5rem !important;
}

.user-details-noti{ 
   animation-duration: 500ms;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 3px #FFFFFF, 0 0 0 4px #CCCCCC, 0 0 2px 5px #DDDDDD;
  color: #444444; 
  position: relative;
  right: 16rem;
  top: 4rem;  
  z-index: 1000; 
 scroll-padding-top: 0px !important;
}

@media screen and (max-width: 1020px) {
.user-details-noti{ 
margin: auto;
}
}

.user-details-noti ol, ul {
    padding-left: 0px !important;
}

.user-details-notiol, ul, dl {
    margin-top: 0;
    margin-bottom: 0px !important;
}

@media screen and (max-width: 1520px) {
/* //  .user-details-noti{ 
//    animation-duration: 500ms;
//   background: #fff;
//   border-radius: 5px;
//   box-shadow: 0 0 0 3px #FFFFFF, 0 0 0 4px #CCCCCC, 0 0 2px 5px #DDDDDD;
//   color: #444444; 
 
//   right: 13.6rem;
//   top: 0rem;  
//   z-index: 1000; 
//  scroll-padding-top: 0px !important;
// } */

}

@media screen and (max-width: 600px) {
  .dropdown-names {
    display: none !important;
  }

/* //   .user-details-noti{ 
//    animation-duration: 500ms;
//   background: #fff;
//   border-radius: 5px;
//   box-shadow: 0 0 0 3px #FFFFFF, 0 0 0 4px #CCCCCC, 0 0 2px 5px #DDDDDD;
//   color: #444444; 
//   position: absolute;
//   right: 7rem;
//   top: 0rem;  
//   z-index: 1000; 
//  scroll-padding-top: 0px !important;
// }
.Messages-button .badge {
   display: none !important;
} */
}

.drop-logout-container{
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.button-logout{
  width: 75px;
  height: 35px;
}

 
 .mobileSidebarbtn:hover{
border: 1px solid   var(--background-blue-color);
padding: 3px;
border-radius: 2px;
 }
 .mobileSidebarbtntwo:hover{
border: 1px solid   var(--background-blue-color);
padding: 3px;
border-radius: 2px;
 }
 .mobileSidebarbtntwo{
/* border: 1px solid   var(--background-blue-color); */
padding: 3px;
border-radius: 2px;
 display: none ;
 }

@media only screen and (max-width: 720px) {
  #header__sidebar{
    display:none !important;
  }

 .mobileSidebarbtn {
    display: none !important;
  } 
 .mobileSidebarbtntwo {
    display: block !important;
}  
.header-logo {
  display:none !important;  

}
.header-logo-mobile{
  display: block;
}

}