@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url(./styles/PerformanceAssessment.css); 
@import url(./styles/LaptopInfo.css);  
@import url(./styles/Policy.css);  
@import url(./styles/css/MobileSideBar.css);

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;  
}

/* styling scrollbars */
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #e6e6e6;
  border-radius: 25px;
}
::-webkit-scrollbar-thumb {
  background: #e2522e;
  border-radius: 25px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ee6644;
}

:root {
  --background-blue-color: #124383;
  --background-white-color: ghostwhite;
  --light-hover-blue: #3670bc;
  --input-field-color: #e6e6e6;
  --theme-orange-color: #e2522e;
}

body {
  background-color: #f1f5f9;
  color: #000;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: normal !important;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  .dashboard-container {
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}  
/* .dashboard-container {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
} */

 
#screen-wrapper {
  display: grid;
  height: 100vh;
  grid-template-columns: -webkit-max-content 3fr;
  grid-template-columns: max-content 3fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas: "header header" "sidenav main";
}

main {
  grid-area: main;
  padding: 30px;
  overflow-y: scroll;
   background: #f8f8f8;
}

/* --- View Profile Screen --- */
.viewprofile-container {
  background-color: #ffffff;
  padding: 7vh 5vh;
}
.viewprofile-container .account-user {
  width: 25vh;
  border-radius: 5px;
  border: 1px solid #e2522e;
}
.viewprofile-container .account-user img {
  width: 100%;
  border-radius: 50px;
}
.viewprofile-details-body {
  margin-top: 5vh;
}
.viewprofile-details-body-header {
  font-size: 1.2rem;
  color: var(--background-blue-color);
  font-weight: 600;
}
.viewprofile-details-row {
  margin-top: 2vh;
}
.viewprofile-details-title {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  margin-bottom: 0;
  font-size: 1rem;
}
.viewprofile-details-content {
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  font-size: 1rem;
}
.viewprofile-container button {
  background-color: var(--background-blue-color);
  letter-spacing: 1px;
  margin-top: 4vh;
  border-radius: 5px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25),
    4px 4px 4px rgba(0, 0, 0, 0.25);
}
.viewprofile-container button:hover {
  background-color: var(--light-hover-blue);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35), 2px 2px 2px rgba(0, 0, 0, 0.35),
    4px 4px 4px rgba(0, 0, 0, 0.35), -1px -1px 1px rgba(0, 0, 0, 0.05),
    -2px -2px 2px rgba(0, 0, 0, 0.05), -4px -4px 4px rgba(0, 0, 0, 0.05);
}
.viewprofile-details-header p {
  font-weight: bold;
}
.viewprofile-details-header p > span {
  background-color: var(--theme-orange-color);
  color: ghostwhite;
  font-weight: 200;
  padding: 3px 5px;
  border-radius: 5px;
}

/* --- Update Profile Screen --- */
.update-profile-tab,
.update-profile-tab .nav-item {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  border: 0;
  font-weight: bold;
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
.update-profile-tab {
  border-bottom: 1px solid #e2e2e2;
}
.update-profile-tab .tab-title {
  color: rgb(255, 255, 255);
  background-color: #929292;
  padding: 2px 5px;
  border-radius: 75%;
  margin-right: 10px;
}
.update-profile-tab .active-tab {
  padding-bottom: 11px;
}
.update-profile-tab .active-tab:hover {
  color: var(--background-blue-color) !important;
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  border-bottom: 1px solid rgb(87, 87, 87) !important;
}
.update-profile-tab .active-tab.active {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: var(--background-blue-color) !important;
  border-bottom: 1px solid #e2522e !important;
}
.update-profile-tab .active-tab.active .tab-title {
  background-color: #e2522e !important;
}

/* --- My Leave --- */
.myleave-wrapper {
  margin-top: 4vh;
}
.modal-content {
  background-color: #ffff;
  border-radius: 3px;
}
.myleave-modal .modal-content .modal-title {
  color: var(--background-blue-color);
}
.myleave-modal .modal-content form {
  padding-left: 2vh;
  padding-right: 2vh;
}
.myleave-modal .modal-content form input[type="text"],
.myleave-modal .modal-content form select {
  margin-bottom: 1vh;
  height: 45px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 2px #e8e8e8;
}
.myleave-modal .modal-content form textarea {
  background-color: #fff !important;
  border-radius: 3px !important;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 2px #e8e8e8;
}
.myleave-modal .modal-content form input:focus,
.myleave-modal .modal-content form select:focus,
.myleave-modal .modal-content form textarea:focus {
  border: 1px solid #e2522e;
  box-shadow: 0 0 2px #e2522e;
}
.myleave-modal .modal-content button {
  border-radius: 3px;
}
.applyleave-btn {
  width: 100% !important;
  background-color: var(--background-blue-color);
  border-radius: 3px;
  color: #fff;
  padding: 10px 15px;
  font-weight: bold;
  outline: none;
  border: none;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 2px rgba(0, 0, 0, 0.25),
  0 0 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.applyleave-btn:hover,
.applyleave-btn:active {
  background-color: #0e3466;
}
.myleave- {
  margin-top: 5vh;
}
.all-leaves select.approveleave-selectinput {
  height: 6vh;
}
.all-leaves select.approveleave-selectinput:focus {
  background-color: var(--input-field-color);
  box-shadow: 2px 0 1px var(--background-blue-color) inset;
  /* outline: 0 none; */
}
.all-leaves button {
  background-color: var(--background-blue-color);
  border-radius: 5px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25),
    4px 4px 4px rgba(0, 0, 0, 0.25);
}
.all-leaves button:hover,
.all-leaves button:focus,
.all-leaves button:active {
  background-color: var(--background-blue-color);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35), 2px 2px 2px rgba(0, 0, 0, 0.35),
    4px 4px 4px rgba(0, 0, 0, 0.35), -1px -1px 1px rgba(0, 0, 0, 0.05),
    -2px -2px 2px rgba(0, 0, 0, 0.05), -4px -4px 4px rgba(0, 0, 0, 0.05);
}
.allLeave-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4vh;
}
.allLeave-title h3,
.allLeave-title h5 {
  color: var(--background-blue-color);
  font-size: large;
  text-transform: capitalize;
  letter-spacing: normal;
  vertical-align: text-top;
}
.allLeave-title p {
  color: var(--background-blue-color);
  font-size: 1rem;
}
.myleave-status-td .status-a {
  color: green;
  text-align: center;
  padding: 2px 0;
  border: 1px solid green;
  border-radius: 3px;
}
.myleave-status-td .status-p {
  color: rgb(122, 122, 68);
  text-align: center;
  border: 1px solid rgb(177, 177, 30);
  border-radius: 3px;
}
.myleave-status-td .status-r {
  color: #e2522e;
  text-align: center;
  border: 1px solid #e2522e;
  border-radius: 3px;
}
.not-eligible {
  margin-top: 2vh;
}
.not-eligible-container {
  margin-bottom: 3vh;
  text-align: center;
}
.not-eligible i {
  color: var(--theme-orange-color);
}
.not-eligible h2 {
  color: var(--theme-orange-color);
}
.not-eligible p {
  font-size: 1rem;
  font-weight: 500;
}
.not-eligible code {
  color: #000;
  font-size: 1.05rem;
  font-weight: bold;
}
/* --- End My Leave --- */

/* --- Staff List ---- */
.go-back-btn,
.create-user-btn {
  background-color: var(--background-blue-color);
  border-radius: 5px;
  color: ghostwhite;
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
.go-back-btn:hover,
.go-back-btn:focus,
.go-back-btn:active,
.create-user-btn:hover,
.create-user-btn:focus,
.create-user-btn:active {
  background-color: var(--background-blue-color);
  color: ghostwhite;
  box-shadow: 1px 1px 1px rgba(61, 44, 44, 0.15),
    2px 2px 2px rgba(0, 0, 0, 0.15), 4px 4px 4px rgba(0, 0, 0, 0.15);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
.staff-list-btn-edit,
.staff-list-btn-key {
  border-radius: 25px;
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
.staff-list-btn-edit {
  margin-right: 1vh;
}
.staff-list-btn-key {
  background-color: var(--background-blue-color);
}
.staff-list-btn-edit:hover,
.staff-list-btn-key:hover {
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
}
/* --- Staff List ---- */

.page-header {
  margin-bottom: 4.5vh;
}
.page-header p {
  font-size: 1rem;
}
.page-header h3,
.page-header h4 {
  text-transform: capitalize;
  letter-spacing: normal;
}
.page-header h3,
.page-header h4,
.page-header p {
  color: var(--background-blue-color);
}

.loading {
  text-align: center;
  margin-top: 15px;
}

.title {
  font-size: 20px;
}

.alert {
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  color: red;
}

/* --- All Leave Application Screen ---- */
a.update-btn {
  background-color: var(--background-blue-color);
  margin: 0.5 !important;
  padding: 5px 20px;
  border-radius: 2px;
  color: ghostwhite;
  text-decoration: none;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
a.update-btn:hover {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35), 2px 2px 2px rgba(0, 0, 0, 0.35),
    4px 4px 4px rgba(0, 0, 0, 0.35), -1px -1px 1px rgba(0, 0, 0, 0.05),
    -2px -2px 2px rgba(0, 0, 0, 0.05), -4px -4px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}

.form-shadow {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 2px 2px 2px rgba(0, 0, 0, 0.05),
    4px 4px 4px rgba(0, 0, 0, 0.05), -1px -1px 1px rgba(0, 0, 0, 0.05),
    -2px -2px 2px rgba(0, 0, 0, 0.05), -4px -4px 4px rgba(0, 0, 0, 0.05);
}

/* --- Update Leave Application Screen ---- */
.update-leave-wrapper form {
  margin-top: 30px;
  border-radius: 5px;
  padding: 5vh 7vh;
}
.update-leave-wrapper h1 {
  color: var(--background-blue-color);
}

/* --- Search Input Field & Button */
.search-input {
  background-color: var(--input-field-color);
  height: 6vh !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.search-input:focus {
  background-color: var(--input-field-color);
  box-shadow: 0 0 0 var(--background-blue-color) inset;
}
.search-btn {
  height: 6vh !important;
  background-color: var(--background-blue-color);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.search-btn:hover,
.search-btn:focus,
.search-btn:active {
  background-color: var(--background-blue-color);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 2px 2px 2px rgba(0, 0, 0, 0.35),
    4px 4px 4px rgba(0, 0, 0, 0.05);
}

/* --- Pagination --- */
.pagination a.page-link {
  background-color: var(--input-field-color);
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.pagination .page-item.active-page {
  background-color: var(--background-blue-color);
}
.pagination li.active a {
  background: var(--background-blue-color);
  color: #fff;
}
.pagination a.page-link:hover {
  background-color: var(--background-blue-color);
  color: ghostwhite;
}
/* --- Pagination --- */
.paginate a.page-link {
  background-color: var(--input-field-color);
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.paginate .page-item.active-page {
  background-color: var(--background-blue-color);
}
.paginate li.active a {
  background: var(--background-blue-color);
  color: #fff;
}
.paginate a.page-link:hover {
  background-color: var(--background-blue-color);
  color: ghostwhite;
}

@media only screen and (max-width: 978px) {
  /* .dashboard-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr 0.2fr;
    grid-template-areas:
      "nav"
      "main";
  } */
}

@media (max-width: 300px) {
  #main-outer-div {
    width: 50%;
    min-width: 0px;
  }
}

/*Small devices {sm} (landscape phones, 576px and up)*/
@media (max-width: 575.98px) {
  header {
    margin-top: -3vh;
  }
  header .xs-header nav {
    margin-left: -4.5vh;
  }
}




.multiselect {
  width: 200px;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
}

#checkboxes label {
  display: block;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}


.-loader {
  color:#124383;
  text-align: center;
  padding: 5rem;
  margin-top: 3rem;
}
.-loader i {
  margin-top: 5rem;
}
.-loader p {
  font-weight: bold;
   margin-bottom: 5rem;
}
.-loader .spinner-border {
  width: 4rem;
  height: 4rem;
}

.-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.-footer > p {
  font-size: medium;
  font-weight: bold;
   color: #124383;
}

.entries-perpage{
  margin-left: -80px;
}
 
.entries-perpage select {
  color: #5c5c5c;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #fff;
  padding: 5px;
  border-radius: 3px;
  outline: none;
  border: 0;
  border: 1px solid #cfcfcf;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.entries-perpage select:focus {
  border: 1px solid #e2522e;
  box-sizing: 0 0 3px #000;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

   .success-color{
color: green;
margin-bottom: 0px;
   }

   .success-border{
border: 2px solid green;
   } 

   .error-border{
    border: 2px solid red; 
   }

   /* agent leave border */

  .border-green{
     border: 1px solid green;
     padding: 3px;
     color: green;
   }
  .border-red{
     border: 1px solid red;
     padding: 3px;
     color: red;
   }
  .border-gold{
     border: 1px solid gold;
     padding: 3px;
     color: gold;
   }

   /* span > .sr-only {
    color: green !important; 
   }
     */
/* css only modal starts here */

.modal1 {
  display: block;
  padding: 0 1em;
  text-align: center;
  width: 100%;
}

@media (min-width: 43.75em) {

.modal1 {
  padding: 1em 2em;
  text-align: right;
}
}

.modal1 > label {
  background: #000;
  border-radius: .2em;
  color: #FFDE16;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin: 0.5em 1em;
  padding: 0.75em 1.5em;
  -webkit-transition: all 0.55s;
  transition: all 0.55s;
}

.modal1 > label:hover {
  -webkit-transform: scale(0.97);
  -ms-transform: scale(0.97);
  transform: scale(0.97);
}

.modal1 input {
  position: absolute;
  right: 100px;
  top: 30px;
  z-index: -10;
}

.modal1__overlay {
  background: black;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: -800;
}

.modal1__box {
  padding: 1em .75em;
  position: relative;
  margin: 1em auto;
  max-width: 500px;
  width: 90%;
}

@media (min-height: 37.5em) {

.modal1__box {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -80%);
  -ms-transform: translate(-50%, -80%);
  transform: translate(-50%, -80%);
}
}

@media (min-width: 50em) {

.modal1__box { padding: 1.75em; }
}

.modal1__box label {
  background: #FFDE16;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  display: inline-block;
  height: 1.5em;
  line-height: 1.5em;
  position: absolute;
  right: .5em;
  top: .5em;
  width: 1.5em;
}

.modal1__box h2 {
  color: #FFDE16;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.modal1__box p {
  color: #FFDE16;
  text-align: left;
}

.modal1__overlay {
  opacity: 0;
  overflow: hidden;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
}

input:checked ~ .modal1__overlay {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 800;
}

input:focus + label {
  -webkit-transform: scale(0.97);
  -ms-transform: scale(0.97);
  transform: scale(0.97);
}


@import url(./styles/ChatScreen.css);  
@import url(./styles/FixedNavbar.css);  
@import url(./styles/Header.css);  
@import url(./styles/JobApplication.css);  
@import url(./styles/LaptopApplication.css);  
@import url(./styles/LaptopInfo.css);  
@import url(./styles/LoginScreen.css);  
@import url(./styles/Main.css);  
@import url(./styles/PerformanceAssessment.css);  
@import url(./styles/PerformanceAssessment.css);  
@import url(./styles/Policy.css);  
@import url(./styles/PrivacyPolicy.css);  
@import url(./styles/ProfileScreen.css);  
@import url(./styles/pagination.css);  
@import url(./styles/AdmimDashboard.css);  
@import url(./App.css);  
@import url(./styles/css/NotFound.css);  



@import '~react-pro-sidebar/dist/css/styles.css';

:root {
  --gradient-blue: #85aec7;
  --accent-blue2:#1243839e;
  --accent-blue: #124383;
  --dark-blue: #334D6E;
  --filter-gray: #6A707E;
  /* --green: #34C988;
  --orange: #FF6F32;
 --yellow: #9e9e00; */
  --white:#ffffff;
  --form-border:#E5E5E5;
  --background:linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #F5F6F8; 
  --box-shadow: 0 0 4px rgba(0, 0, 0, .05), 0 0 16px rgba(0, 0, 0, .05), 0 0 32px rgba(0, 0, 0, .05), 0 0 64px rgba(0, 0, 0, .05);
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
    flex-grow: 1;
    padding-top: 2rem !important;
    border-right: 1px solid #e8e8e8 !important;
    overflow: none !important;
     
}
.pro-sidebar > .pro-sidebar-inner {
     background: #0e3466;  
    height: 100%;
    position: relative;
    z-index: 101;
}
.pro-sidebar{
font-size: 14px !important; 
 font-weight: bold;
 color: #fff;
  overflow-y: none !important;  
}
.pro-sidebar a{
font-size: 14px !important; 
 font-weight: bold;
 color: #fff !important;
  overflow-y: none !important;  
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    position: relative;
    background-color: #005b90;
    font-weight: 300;

}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: #0e346600;
}
/* .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: #fff;
} */

.Side__Content:hover {
    
   background-color: #005b90;
    color: #fff !important;
    
  }
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content .active{
  /* //  border-left: 7px solid #005b90  !important;  */
   color: #124383 !important;
   background-color: #fff !important;
   font-weight: bold;
  }
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content .active a{
  /* //  border-left: 7px solid #005b90  !important;  */
   color: #124383 !important;
   background-color: #fff !important;
   font-weight: bold;
  }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    max-height: 100vh;
    overflow-y: auto;
    background-color: #005b90;
    padding-left: 20px;
    border-radius: 4px;
    color: #fff;
}

  /* .isEmployee-hour-hand{
    margin-bottom: 17rem;
    // height: 20rem !important;
  } */
   
 .pro-sidebar .pro-menu .pro-menu-item  .pro-sidebar-content .active{
    color: #0e3466 !important;
       background-color: #0e3466 !important;
}

/* //  .pro-sidebar-inner .pro-sidebar-layout .logotext p {
// font-size: 10px;
// padding: 0 10px; 
 
// }
 .pro-sidebar-inner .pro-sidebar-layout ul {
padding: 0 3px;
}
 .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
// color: #000;
font-size: smaller; 
padding: 0 10px; 
} */

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    margin-right: 10px;
    font-size: 14px; 
    min-width: 35px;
    height: 28px !important;
    line-height: 35px;
    text-align: center;
    display: inline-block;
}
/* //  .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
// background-color: #c0c0c0;
// color: #000;
// border-radius: 3px;
// }
//  .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
// color: #000;
// }
//  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
//     position: relative;
//     display: flex;
//     align-items: center;
//     padding: 8px 35px 8px 20px;
//     cursor: pointer; 
// } */



#reports table { 
  box-shadow: 0 0 5px rgba(0, 0, 0, .05) !important;
  
}

#reports table .table-link {
  background-color: rgba(16, 156, 241, .1) !important;
  color: var(--accent-blue);
  padding: .25rem 1rem !important;
  border-radius: 3px !important;
  border: 2px solid var(--accent-blue) !important;
}

#reports tbody tr td:nth-child(1) {
  color: var(--dark-blue);
  font-weight: 600 !important;
  white-space:nowrap;
}

#reports .nav-tabs {
  background-color: #fff !important;
  list-style: none !important;
  width: fit-content !important;
  display: flex !important; 
  margin-top: 1.25rem !important;
}

/* Media query for screen sizes less than 768px */
@media screen and (max-width: 767px) {
    #reports .nav-tabs {
        flex-wrap: wrap;
        width: 100% !important;
    }
    #reports .nav-tabs li {
        flex: 0 0 50%; /* Adjust the width as needed */
        text-align: center; /* Center-align individual tabs */
        font-size: small;
       
    }
}

 
#reports .nav-tabs li {
  padding: .5rem 1rem !important;
  color: #272525 !important;
  cursor: pointer !important;
  border: 1px solid #d8d8d8 !important;
  
}
/* #reports .nav-tabs li {
  padding: .5rem 1rem !important;
  color: #272525 !important;
  cursor: pointer !important;
  border: 1px solid #d8d8d8 !important;
} */

#reports .nav-tabs li.active {
  color: var(--accent-blue) !important;
  border: 1px solid var(--accent-blue) !important;
  /* white-space: nowrap; */
}

#reports .nav-tabs{
margin-bottom: 1rem;
}


.page-title {
    color: #6a707e;
     font: 18.72px  ;
     font-weight: bold;
}



/* Your CSS code */
.md-ui.component-data-table {
  background: #fff;
  border: 0;
  border: 1px solid #e8e8e8 !important;
  font-size: 13px;
  font-weight: 400;
  margin: 2em 0px 0px 0px;
  width: auto;
}

.md-ui.component-data-table .main-table-header {
  padding: 24px 14px 24px 24px;
}

.md-ui.component-data-table .main-table-header .table-header--title {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 100;
  font-size: 20px;
  margin: 0;
  display: inline-block;
}

.md-ui.component-data-table .main-table-header .table-header--icons {
  font-size: 24px;
  float: right;
  vertical-align: middle;
}

.md-ui.component-data-table .main-table-header .table-header--icons .material-icons:first-child {
  padding-right: 24px;
}

.md-ui.component-data-table .main-table-wrapper {
  display: block;
  max-width: 100%;
  overflow-x: auto;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  overflow: hidden;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content tr {
  border-bottom: 1px solid #e2522e;
 
}
.md-ui.component-data-table .main-table-wrapper .main-table-content th{
     padding: 0 14px 0 24px;
 
}


.md-ui.component-data-table .main-table-wrapper .main-table-content tr td {
  text-overflow: ellipsis;
  padding: 1rem;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content tr td:first-child {
  padding: 0 14px 0 24px;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content tr td:last-child {
  padding: 0 14px 0 0;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content tr td.datatype-string {
  text-align: left;
}
 
.md-ui.component-data-table .main-table-wrapper .main-table-content .data-table-header tr {
  height: 2.5rem;
  padding: 0 24px !important;
  font-weight: bold !important;
  padding-right:20px;

}

.md-ui.component-data-table .main-table-wrapper .main-table-content .data-table-header tr td {
  color: #808080;
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content .data-table-header tr td .material-icons {
  font-size: 18px;
  vertical-align: middle;
  padding-right: 8px;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content .data-table-content tr {
  height: 48px;
  cursor: pointer;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content .data-table-content tr:hover {
  background: #eee;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content .data-table-content tr td {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content .data-table-content tr td input[type="text"] {
  background: transparent;
  border: 0;
  color: #212121;
  font-size: 13px;
  font-weight: 400;
  height: 40px;
}

.md-ui.component-data-table .main-table-wrapper .main-table-content .data-table-content tr td input[type="text"]::placeholder {
  color: rgba(0, 0, 0, 0.38);
}

.md-ui.component-data-table .main-table-wrapper .main-table-content .data-table-content .css-b62m3t-container {
  position: unset !important;
}

.md-ui.component-data-table .main-table-wrapper .main-table-footer {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  text-align: right;
  padding: 22px 0;
}

.md-ui.component-data-table .main-table-wrapper .main-table-footer span {
  vertical-align: middle;
}

.md-ui.component-data-table .main-table-wrapper .main-table-footer span .material-icons {
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
}

.md-ui.component-data-table .main-table-wrapper .main-table-footer span.rows-selection {
  padding-right: 32px;
}

.md-ui.component-data-table .main-table-wrapper .main-table-footer span.rows-selection .rows-selection-label {
  padding-right: 38px;
}

.md-ui.component-data-table .main-table-wrapper .main-table-footer span.rows-amount {
  padding-right: 32px;
}

.md-ui.component-data-table .main-table-wrapper .main-table-footer span.table-paginations {
  padding-right: 14px;
}

.md-ui.component-data-table .main-table-wrapper .main-table-footer span.table-paginations .material-icons:first-child {
  padding-right: 24px;
}
.md-ui.component-data-table .main-table-wrapper .main-table-content .data-table-header tr td {
    font-weight: bold !important;
    padding: 3px;
    color: "#808080" !important;
    padding: 0 20px; 
    font-size: 12px;
    font-weight: 500;
    vertical-align: middle;
      white-space: no-wrap !important;
}


th {
    font-weight: bold !important;
    padding: 3px;
    color: "#808080" !important;
    padding: 0 20px; 
    font-size: 12px;
    font-weight: bold !important;
    vertical-align: middle;
  white-space: nowrap !important;
}

.table-active-items {
  display: flex;
  justify-content: space-around;
}

.paginations {
  margin: 1rem 0px;
  border-radius: 3px;
  padding: 2px 2px 3px 1px;
  display: inline-block;
}

.paginations ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
}

.paginations li {
  float: left;
  margin: 0 4px 0 0;
  font-size: 12px;
}

.paginations a {
  display: flex;
  float: left;
  background: #fff;
  padding: 10px;
  border: 1px solid #d9d9d9;
  color: #666;
  text-decoration: none;
  width: 32px;
  height: 32px;
  align-items: center;
}
@media (max-width: 1300px) {

  .dashboard-cards-admin {
    display: grid;
    grid-template-columns: 1fr  1fr;
    grid-gap: 30px;
    gap: 30px;
    margin: 20px 0;
}
.charts {
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 50px;
}}
@media (max-width: 1300px) {

  .dashboard-cards-admin {
    display: grid;
    grid-template-columns: 1fr  1fr;
    grid-gap: 30px;
    gap: 30px;
    margin: 20px 0;
}
.charts {
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 50px;
}}


/* Media query for mobile devices */
@media (max-width: 768px) {

  .dashboard-cards-admin {
    display: grid;
    grid-template-columns: 1fr  ;
    grid-gap: 30px;
    gap: 30px;
    margin: 20px 0;
}
  .half-background-input {
    flex-direction: column;
  }

  .item, .entries-per-page {
    width: 100%;
    margin-bottom: 10px;
  }

  .CSV-button, .-Button {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .pay_parameter {
    flex-direction: column;
    margin-top: 20px;
  }
}
/* Default styles for desktop */
.half-background-input {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 240px;
  /* // border: 1px solid c.color(primary-color); */
}

.entries-per-page {
  width: 240px;
}

.pay_parameter {
  display: flex;
  align-items: center;
}

.CSV-button,
.-Button {
  margin-right: 10px;
}


.custom-textarea::placeholder {
  /* Style the placeholder here */
  color: #999; /* Example color */
  font-weight: 400;
  font-size: small;
   /* Example padding */
}

.custom-textarea {
  /* Add padding to the textarea */
  padding: 10px; /* Example padding */
  /* Add any other styles specific to this textarea */
}


.table-loader {
  color: #cacaca !important;
  text-align: center !important;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.table-loader p {
  color: #7a7a7a;
}



