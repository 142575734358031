.document-table th,
.document-table td {
  border: 1px solid black;
  padding: 0 10px;
}
.document-table table {
  width: 100%;
}
.document-title {
    margin: 20px 0;
}
.document-title p {
  font-weight: 700;
  font-size: 16px;
  margin: 0 !important;
}
.introduction h1{
    font-size: 14px;
      margin: 20px 0;
      text-transform: none;
     letter-spacing: normal;
}
.document-outline{
    line-height: 3;
}
.reasons-list{
  margin-left: 30px;
  list-style:inside ;
}
.reasons-list-two{
    margin-left: 30px;
  list-style: lower-alpha;
}
.reason-list-three{
     margin-left: 30px;
  list-style: disc;
}
.reason-list-four{
list-style: circle;
  margin-left: 30px;
}
.reason-list-five{
  list-style:upper-alpha;
  margin-left: 30px;
}
.reasons ol{
    margin-left: 20px;
}