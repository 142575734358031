   .panel{
     border: 0;
   }
   .laptop-info{
  color: #0e3466;
  border: 1px solid #0e3466;
  background-color:#e2e2e20e;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px 10px;
  text-decoration: none;
 
}

.laptop-info:hover{
background-color:#0e3466;
color:#e2e2e2;
}

  .laptop-info:focus{
background-color:#e2e2e204;
color:#0e3466;
}

  /* .laptop-info:visited{
background-color:#0e3466;
color:#e2e2e2;
} */
