.wrap-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  padding: 2rem; 
  letter-spacing: 0px !important;
}

/* @media only screen and (max-width: 978px) {
  .wrap-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box; 
  letter-spacing: 0px !important;
}

}
@media only screen and (max-width: 516px) {
  .wrap-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;

  letter-spacing: 0px !important;
  width: auto;
}

} */

.onScroll {
  overflow-x: hidden;
  overflow-y: auto;
}

#Scroll-body {
  height: 50vh !important;
}

.onScroll h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0px !important;
}

.container-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem;
  background-color: #fff;
  width: 768px;
  height: 100%;
  border-radius: 0.25rem;
  box-shadow: 0rem 1rem 2rem -0.25rem rgba(0, 0, 0, 0.25);
  letter-spacing: 0px !important;
}
.container__heading {
  /* justify-content: center !important; */
  text-align: center !important;
  /* line-height: 1.75rem;   */
  letter-spacing: 0px !important;
  width: 100% !important;
}

.modal-header {
  display: block !important;
}

.modal-header .close #example-modal-sizes-title-lg {
  color: red;
}
.modal-header .container__heading > h2 {
  letter-spacing: 0px !important;
  margin: auto;
  text-align: center;
}
.container__content {
  overflow-y: scroll;
}
.container__nav {
  /* border-top: 1px solid #ccc; */
  text-align: right;
  /* padding: 2rem 0 1rem; */
}
.container__nav > .Accept-button {
  background-color: var(--background-blue-color);
  box-shadow: 0rem 0.5rem 1rem -0.125rem rgba(0, 0, 0, 0.25);
  padding: 0.8rem 2rem;
  border-radius: 0.2rem;
  border: none !important;
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: transform 0.25s, box-shadow 0.25s;
  border-color: none;
  outline: none;
  z-index: 99999;
}
.container__nav > .Accept-button:hover {
  box-shadow: 0rem 0rem 1rem -0.125rem rgba(0, 0, 0, 0.25);
  outline: 1px solid var(--background-blue-color);
  background-color: #fff;
  color: var(--background-blue-color);
  transform: translateY(-0.5rem);
}
.container__nav > small {
  color: #777;
  margin-right: 1rem;
}

#include__ol {
  display: flex;
  flex-direction: row;
}

#include__ol-h5 {
  margin-left: 2.5rem;
  margin-right: 1rem;
}

.nauthorized__span {
  margin-left: 2rem;
}
.nauthorized__span-space {
  margin-bottom: 3px !important;
}

.container__content + h2 h3 h4 h5 h6 {
  /* border-bottom: 1rem solid orangered; */
}

