#sidebar {
  background-color: var(--background-blue-color);
  grid-area: sidebar;
  overflow-y: auto;
  padding-bottom: 15px;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.fixednavbar-menu {
  margin-top: 5vh;
}
.fixednavbar-menu ul li {
  background-color: var(--background-blue-color);
  list-style: none;
}
.fixednavbar-menu a.nav-link {
  color: rgba(248, 248, 255, 0.67);
  padding: 1.5vh 0;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 1.8vh 3vh;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
.fixednavbar-menu a.nav-link i {
  color: ghostwhite;
  margin-right: 2vh;
}
.fixednavbar-menu a.nav-link:hover {
  color: ghostwhite;
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.02), 2px 2px 2px rgba(0, 0, 0, 0.02),
    4px 4px 4px rgba(0, 0, 0, 0.02), 6px 6px 6px rgba(0, 0, 0, 0.02);
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}
.fixednavbar-menu a.nav-link.active-here {
  color: ghostwhite;
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.02), 2px 2px 2px rgba(0, 0, 0, 0.02),
    4px 4px 4px rgba(0, 0, 0, 0.02), 6px 6px 6px rgba(0, 0, 0, 0.02),
    4px 0 ghostwhite inset;
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}

.fixednavbar-title {
  background-color: #fff;
  color: var(--light-hover-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 30px;
  border-bottom: 1px solid ghostwhite;
}

.fixednavbar-img img {
  width: 100%;
}

.fixednavbar-title > i {
  font-size: 18px;
  margin: 0 3vh;
  margin-left: 5vh;
  display: none;
}
.fixednavbar-responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
  width: 70%;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
  }

  .fixednavbar-title > i {
    display: inline;
  }
}
