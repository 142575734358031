.empty-icon-container{
  margin: auto;
  text-align:center;
  display:flex;
  flex-direction: column;
  align-items: center; 

}
.animation-container{
  position:relative;
  display:block;
  text-align: center;
  height:100px;
  width:100px;
  border-bottom: solid 2px #124383;
  overflow:hidden;
}
.bounce{
  display: inline-block;
  position:absolute;
  bottom:0;
  left:50%;
  width:40px;
  height:40px;
  margin-left: -20px;
  background-size: contain;
  animation: bounceAnim 1s cubic-bezier(.63,.09,.75,.46) infinite alternate,
            spinAnim 3s linear infinite;
}

@keyframes bounceAnim{
  0%, 10% { bottom: 50%; }

  100% { bottom: 0%; }
}
@keyframes spinAnim{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pebble1{
  position:absolute;
  top:95%;
  background-color:#124383;
  width:10px;
  height:10px;
  border-radius: 20px 20px 0px 0px;
  animation: pebblesAnim 1s linear infinite;
}
.pebble2{
  position:absolute;
  top:97%;
  background-color:#124383;
  width:5px;
  height:5px;
  border-radius: 10px 10px 0px 0px;
  animation: pebblesAnim 2s linear infinite;
}
.pebble3{
  position:absolute;
  top:98%;
  background-color:#124383;
  width:3px;
  height:3px;
  border-radius: 20px 20px 0px 0px;
  animation: pebblesAnim 3s linear infinite;
}

@keyframes pebblesAnim{
  0% { right: -20%; }
  100% { right: 110%; }
}


.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}


.btn-3 {
  background: #124383;
background: linear-gradient(0deg, #124383 0%, #124383 100%);
  width: 130px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
  
}
.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
   background: rgba(2,126,251,1);
  transition: all 0.3s ease;
}
.btn-3:before {
  height: 0%;
  width: 2px;
}
.btn-3:after {
  width: 0%;
  height: 2px;
}
.btn-3:hover{
   background: transparent;
  box-shadow: none;
}
.btn-3:hover:before {
  height: 100%;
}
.btn-3:hover:after {
  width: 100%;
}
.btn-3 span:hover{
   color: #124383;
}
.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
   background: #124383;
  transition: all 0.3s ease;
}
.btn-3 span:before {
  width: 2px;
  height: 0%;
}
.btn-3 span:after {
  width: 0%;
  height: 2px;
}
.btn-3 span:hover:before {
  height: 100%;
}
.btn-3 span:hover:after {
  width: 100%;
}